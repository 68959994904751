import React from "react";

class KntoPolicyText extends React.Component {
  render() {
    return (
      <div className="consent-bx" style={{ height: 380 }}>
        {/*<div style={{ textAlign: 'center' }}>*/}
        {/*  <strong>*/}
        {/*    개인정보 수집 및 이용 동의*/}
        {/*  </strong>*/}
        {/*</div>*/}
        <p>
          한국관광콘텐츠랩 홈페이지는 아래와 같이 개인정보를 수집 및 이용하고자
          합니다.
          <br />
          <br />
        </p>
        <p>
          1. 개인정보의 수집 및 이용 목적
          <br />
          <br />
          &nbsp;&nbsp;○ 회원가입 및 관리
          <br />
          &nbsp;&nbsp;○ 한국관광콘텐츠랩 서비스 제공
          <br />
          &nbsp;&nbsp;○ 민원처리
          <br />
          &nbsp;&nbsp;○ 저작물 관리 등<br />
          <br />
        </p>
        <p>
          2. 개인정보의 수집 및 이용 항목
          <br />
          <br />
          &nbsp;&nbsp;○ 필수항목 : 아이디, 비밀번호, 이름, 이메일, 연락처
          <br />
          <br />
        </p>
        <p>
          3. 개인정보의 보유 및 이용 기간 :{" "}
          <strong style={{ fontSize: "120%", textDecoration: "underline" }}>
            회원가입 동의일로부터 회원탈퇴 시까지 보유
          </strong>
          <br />
          <br />
          &nbsp;&nbsp;※ 저작권법 제41조에 근거하여 CONLAB에서 제공하는 저작물에
          대한 저작권 처리 방안
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(저작물 말소 시 저작물에 대한 책임소재,
          저작권 문제 발생, API에 의한 콘텐츠 공유 등) 관리
          <br />
          &nbsp;&nbsp;○ 단체폐쇄 및 단체탈회의 경우 개인정보를 파기하지 않고 비활성화 처리 합니다.
          <br />
          <br />
        </p>
        <p>
          4. 동의거부 권리 및 불이익
          <br />
          <br />
          &nbsp;&nbsp;○ 위와 같이 개인정보 수집 및 이용에 대한 동의를 거부하실
          수 있으나, 동의 거부 시 회원가입이 불가하며 서비스 이용이 제한될 수
          있습니다.
          <br />
          <br />
        </p>
      </div>
    );
  }
}

export default KntoPolicyText;
