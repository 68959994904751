import React from "react";
import { defaultListCard, makeThemeDepotImageUrl } from "../shared/ImagePathUtil";

interface Props {
   cid:  string;
   categoryId: string;
   text: string;
   imagePath: string;
   setSelectedCid: (cid: string) => void;
   setSelectedCategoryId: (categoryId: string) => void;
   findedThemeSummary: {
    id: string,
    contentsName: string,
    cineroomName: string,
    registrationTime: string,
    imagePath: string,
  }[];
  findedImageSummary: {
    categoryPath: string,
    cid: string,
    contentsName: string,
    imagePath: string,
    imageType: string,
    koglType: string,
    modificationTime: string,
    score: number,
    tid: string
  }[];
  findedVideoSummary: {
    cid: string,
    tid: string,
    contentsName: string,
    thumbnailPath: string,
    koglType: string,
    categoryPath: string,
    modificationTime: string
  }[];
  findedFileSummary: {
    cid: string,
    rowNumber: 1,
    tourismName: string;
    contentsName: string;
    fileType: string;
    cineroomName: string;
    registrationTime: string
  }[];
  onClickDetailModal: () => void;
  onClickThemeModal: (modalType: string, category_id: string) => void;
}

interface State {
  selectedCid: string
}

class Card extends React.Component<Props, State> {
  //@ts-ignore
  static defaultProps: Props = {
    cid: '',
    text: '',
    imagePath: ''
  };

  //@ts-ignore
  state: State = {
    selectedCid: '',
  };

  eventSetSelectedCid(cid : string) {
    this.props.setSelectedCid(cid);
  }

  eventSetSelectedCategoryId(categoryId : string) {
    this.props.setSelectedCategoryId(categoryId)
  }

  defaultImage() {
    const defaultImage = `/rms-conlab/images/alternative_283x188.svg`;
    return defaultImage;
  }

  openDetail(){
    this.props.onClickDetailModal()
  }

  openTheme(modalType: string, category_id: string){
    this.props.onClickThemeModal(modalType, category_id)
  }

  renderFile(){

    const { findedFileSummary, cid } = this.props

    return (
        <>
          {findedFileSummary.map((obj: any, index: number) => {
            return (
            <div className="board_wrap_file">
              <ul className="board_write_list">
                <li className="img_add_area">
                  <div className="con">
                    <table className="file_list">
                      <tbody>
                        <tr>
                          {/* <th className="t_left" style={{width: '10%'}}>번호</th> */}
                          <th style={{width:'40%'}}>제목</th>
                          <th>첨부파일</th>
                          <th>단체명</th>
                          <th>등록일</th>
                        </tr>
                        <tr>
                          {/* <td className="t_left">{obj.rowNumber}</td> */}
                          <td onClick={() => {this.eventSetSelectedCid(cid)}}>{obj.contentsName}</td>
                          <td>{obj.fileType}</td>
                          <td>{obj.cineroomName}</td>
                          <td>{obj.registrationTime}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
            );
          })}
        </>
    );
  }

  renderImage(){
    const { cid, text, imagePath } = this.props;

    return(
      <>
        <a>
          <div className="img_box" style={{cursor: 'pointer'}} onClick={() => {this.eventSetSelectedCid(cid); this.openDetail()}}>
            <img
              src={imagePath}
              alt="oas_img"
              onContextMenu={(e) => (e.preventDefault())}
              onError={(error) => {
                error.currentTarget.src = this.defaultImage();
              }}
            />
          </div>
          <h5 style={{marginTop: '16px'}}>{text}</h5>
        </a>
      </>
    )
  }

  renderVideo(){
    const { cid, text, imagePath } = this.props;

    return(
      <>
        <a>
          <div className="img_box" style={{cursor: 'pointer'}} onClick={() => {this.eventSetSelectedCid(cid); this.openDetail();}}>
            <img
              src={imagePath}
              alt="oas_img"
              onContextMenu={(e) => (e.preventDefault())}
              onError={(error) => {
                error.currentTarget.src = this.defaultImage();
              }}
            />
          </div>
          <h5 style={{marginTop: '16px'}}>{text}</h5>
        </a>
      </>
    )
  }

  renderTheme(){
    const { categoryId, text, imagePath } = this.props;
    const tongImageUrl = imagePath.split('|')[0];
    const depotImageUrl = imagePath.split('|')[1];
    console.log(depotImageUrl, 'depotImageUrl');

    ///api/depot/public/depot-flow/query/download-image/a30ddd81-1188-431f-8708-f66e9ce88d56


    // const depotImageUrl = makeThemeDepotImageUrl(imagePath)
    // const url = depotImageUrl.split('http://conlab-dev.visitkorea.or.kr')
    // const combineUrl = url[1]
    // const depotUrl = `${depotImageUrl}`

    return(
      <>
      <a>
        <div className="img_box" style={{cursor: 'pointer'}} onClick={() => {this.eventSetSelectedCategoryId(categoryId); this.openTheme("theme", categoryId);}}>
          <img
            style={{height: '170px'}}
            src={depotImageUrl + '/it7'}
            alt="oas_img"
            onContextMenu={(e) => (e.preventDefault())}
            onError={(error) => {
              if (tongImageUrl) {
                error.currentTarget.src = tongImageUrl;
                error.currentTarget.onerror = () => {
                  error.currentTarget.src = this.defaultImage();
                };
              } else {
                error.currentTarget.src = this.defaultImage();
              }
          }}
          />
        </div>
        <h5 style={{marginTop: '16px'}}>{text}</h5>
      </a>
      </>
    )
  }

  componentDidMount() {}

  render() {
    const { findedThemeSummary, findedImageSummary, findedVideoSummary, findedFileSummary } = this.props;
    return (
      <>
        {(findedImageSummary?.length > 0) && this.renderImage()}
        {(findedVideoSummary?.length > 0) && this.renderVideo()}
        {(findedThemeSummary?.length > 0) && this.renderTheme()}
        {(findedFileSummary?.length > 0) && this.renderFile()}
      </>
    );
  }
}

export default Card;
