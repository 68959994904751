import React, { ContextType } from "react";
import "../../css/common.css";
import ContentBuilder from "../../model/builder/ContentBuilder";
import { KoglType } from "../../model/type/KoglType";
import BookMarkButton from "../../components/BookMarkButton";
import InterestsType from "../../model/type/interestsType";
import { registerUsage } from "../../shared/Function/util/RegisterUtil";
import { SearchType } from "../../model/type/SearchType";
import { UsageType } from "../../model/type/UsageType";
import CommonContext from "../../context/CommonContext";
import { ContentType } from "../../../src/model/type/ContentType";

interface Props {
  cid: string;
  interestsType: InterestsType;
  upDateVideoSummaryDate: {
    cid: string,
    tid: string,
    contentsName: string,
    thumbnailPath: string,
    koglType: string,
    categoryPath: string,
    modificationTime: string
  }[];
  onCloseModal: () => void;
  onClickThemeCloseModal: () => void;
  eventSetSelected: () => void;
  viewWidth: number | undefined;
  noPrev?: boolean; 
  contentsType: string;
  showImage?: () => void;
  scrollToTop?: () => void;
}

interface State {
  contentDetailData: any;
  showShareAlert: boolean;
}

class VideoContentsDetail extends React.Component<Props, State> {

  static defaultProps: any = {
    noPrev: false
  };
  static contextType = CommonContext;
  context!: ContextType<typeof CommonContext>;

  state: State = {
    contentDetailData: {},
    showShareAlert: false
  };

  componentDidMount() {
    this.initContentDetailDataByCid(this.props.cid);
    this.updateViewCount();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.cid !== this.props.cid) {
      this.initContentDetailDataByCid(this.props.cid);
      this.updateViewCount();
    }

    if(prevProps.upDateVideoSummaryDate !== this.props.upDateVideoSummaryDate){
      this.setState({contentDetailData : {}})
    }
  }

  async initContentDetailDataByCid(cid: string) {
    const { scrollToTop } = this.props;

    const contentBuilder = new ContentBuilder(cid);
    await contentBuilder.buildVideoContent();
    const contentDetailData = contentBuilder.getContentDetailInfo();
    this.setState({contentDetailData: contentDetailData});
    if (scrollToTop) {
      scrollToTop();
    }
  }

  updateViewCount() {
    const { cid } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;
    if (!cid) {
      return;
    }
    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;
    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, SearchType.Video, UsageType.ViewCount);
  }

  onClickShare(){

    const { cid, interestsType } = this.props;
    const { actorIdentity, citizenUser, accessibleCinerooms } = this.context;

    const currentActorId = actorIdentity?.id;
    const email = actorIdentity?.email;
    const cineroomId = citizenUser?.cineroomIds[0];
    const cineroomName = accessibleCinerooms[0]?.name;

    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const base_url = window.document.location.host;
    let urlValue = '';
    urlValue = `${base_url}/rms-conlab/detail?` + "cid=" + this.props.cid + "&type=" + ContentType.VideoContents;

    textarea.value = urlValue;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.setState({ showShareAlert: true });
    setTimeout(() => this.setState({ showShareAlert: false }), 2000);

    registerUsage(cid, currentActorId, email, cineroomId, cineroomName, interestsType, UsageType.LinkCreated);
}

  render() {
    const { cid, viewWidth, noPrev, eventSetSelected, onCloseModal, onClickThemeCloseModal, showImage } = this.props;
    const { contentDetailData, showShareAlert } = this.state;

    if (Object.keys(contentDetailData).length === 0) {
      return;
    }

    return(
      <div className="rd_view_item active">
        {/* @ts-ignore */}
        {viewWidth === undefined ? null :
          <div className="m_head">
          { noPrev === false && (<button className="rd_view_prev_bt" onClick={showImage ? ()=>{eventSetSelected(); showImage();} : () => (eventSetSelected())}><img src="/rms-conlab/images/icon_rd_view_prev.png" alt="close_icon"/></button>)}
              {this.props.contentsType === 'ThemeContents' ? null :
                  <button className="close_bt rd_view_close_bt" onClick={() => {onCloseModal(); onClickThemeCloseModal();}}><img src="/rms-conlab/images/icon_menu_close.svg" alt="close_icon"/></button>
              }
          </div>
        }
      <div className="contents_detail_page">
        <div className="cdp_tit_area">
          <h2 className="cdp_tit">{contentDetailData.baseInfo.value.title.value}</h2>
          <div className="bts">
              <button className="share" onClick={() => this.onClickShare()} style={{position: 'relative', top: '6px'}}></button>
              {
                showShareAlert && <div className="share_copy_video"><span>URL이 복사되었습니다. 원하는 곳에 붙여 넣으세요.</span></div>
              }
          </div>
            <BookMarkButton
                cid = {cid}
                interestsType = {InterestsType.Video}
            />
        </div>
        <div className="rd_view_video_area">
            <iframe
              style={{
                width: "450px",
                height: "250px",
              }}
              src={`${contentDetailData.video.value.youtubeUrl.value}`}
              title={contentDetailData.baseInfo.value.title.value}
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
        </div>

        <div className="contents_detail_info_area">
          <div className="category">
            <h3>{contentDetailData.baseInfo.value.categoryPath.title}</h3>
            <ul>
              <li>{contentDetailData.baseInfo.value.categoryPath.value}</li>
            </ul>
          </div>
          <div className="contents_detail_info">
            <div className="left">
              <h4 className="cdi_tit">{contentDetailData.baseInfo.value.address.title}</h4>
              <p className="exp address">{contentDetailData.baseInfo.value.address.value}</p>

              <h4 className="cdi_tit">{contentDetailData.baseInfo.value.videoDescription.title}</h4>
              <p className="exp pre">{contentDetailData.baseInfo.value.videoDescription.value}</p>
            </div>
            <div className="right">
              <ul className="visit_info">
                {/* <li>
                  <i className="icon youtube"></i>
                  <div className="ex">
                    <h5>YouTube URL</h5>
                    <p><a href="https://youtu.be/gux_pyEIcUY" target="_blank">https://youtu.be/gux_pyEIcUY</a><button className="copy"><img src="/rms-conlab/images/icon_copy.png" alt=""/></button></p>
                  </div>
                </li> */}
                <li>
                  <i className="icon time"></i>
                  <div className="ex">
                    <h5>{contentDetailData.baseInfo.value.videoDuration.title}</h5>
                    <p>{contentDetailData.baseInfo.value.videoDuration.value}</p>
                  </div>
                </li>
                {/* <li>
                  <i className="icon age"></i>
                  <div className="ex">
                    <h5>연령제한</h5>
                    <p>-</p>
                  </div>
                </li> */}
                <li>
                  <i className="icon tag"></i>
                  <div className="ex">
                    <h5>{contentDetailData.baseInfo.value.tags.title}</h5>
                    <p>{contentDetailData.baseInfo.value.tags.value}</p>
                  </div>
                </li>
                <li>
                  <i className="icon copyright"></i>
                  <div className="ex">
                    <h5>{contentDetailData.baseInfo.value.providerName.title}</h5>
                    <p>{contentDetailData.baseInfo.value.providerName.value}</p>
                  </div>
                </li>
                <li>
                  <i className="icon maker_video"></i>
                  <div className="ex">
                    <h5>{contentDetailData.baseInfo.value.owner.title}</h5>
                    <p>{contentDetailData.baseInfo.value.owner.value}</p>
                  </div>
                </li>
                {/* <li>
                  <i className="icon provider"></i>
                  <div className="ex">
                    <h5>제공자명</h5>
                    <p>-</p>
                  </div>
                </li> */}
                {/* <li>
                  <i className="icon call"></i>
                  <div className="ex">
                    <h5>제공자 연락처</h5>
                    <p>-</p>
                  </div>
                </li> */}
                {/* <li>
                  <i className="icon mail"></i>
                  <div className="ex">
                    <h5>제공자 이메일</h5>
                    <p>-</p>
                  </div>
                </li> */}
                <li>
                  <i className="icon original"></i>
                  <div className="ex">
                    <h5>{contentDetailData.baseInfo.value.originRequestMethod.title}</h5>
                    <p>{contentDetailData.baseInfo.value.originRequestMethod.value}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="contents_detail_info">
            <div className="left">

            <div className="left">
              <h4 className="cdi_tit">{contentDetailData.registrationInfo.value.kogl.title}</h4>

              {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type1 && (
                <>
                  <p className="exp copyright">본 저작물은 "공공누리" 제1유형:출처표시 조건에 따라 이용 할 수 있습니다.</p>
                  <div className="dt-list-down">
                    <img className="copyright_img" src="/rms-conlab/images/img_opentype01.jpg" alt="copyright"/>
                  </div>
                </>
              )}
              {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type2 && (
                <>
                  <p className="exp copyright">본 저작물은 “공공누리” 제2유형:출처표시+상업적 이용금지 조건에 따라 이용할 수 있습니다.</p>
                  <div className="dt-list-down">
                    <img className="copyright_img" src="/rms-conlab/images/img_opentype02.jpg" alt="copyright"/>
                  </div>
                </>
              )}
              {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type3 && (
                <>
                  <p className="exp copyright">본 저작물은 “공공누리” 제3유형:출처표시+변경 금지 조건에 따라 이용할 수 있습니다.</p>
                  <div className="dt-list-down">
                    <img className="copyright_img" src="/rms-conlab/images/img_opentype03.jpg" alt="copyright"/>
                  </div>
                </>
              )}
              {contentDetailData.registrationInfo.value.kogl.value && contentDetailData.registrationInfo.value.kogl.value === KoglType.Type4 && (
                <>
                  <p className="exp copyright">본 저작물은 “공공누리” 제4유형:출처표시+상업적 이용금지+변경 금지 조건에 따라 이용할 수 있습니다.</p>
                  <div className="dt-list-down">
                    <img className="copyright_img" src="/rms-conlab/images/img_opentype04.jpg" alt="copyright"/>
                  </div>
                </>
              )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default VideoContentsDetail;
